import { AccountActions } from "./AccountActions";
import { AccountState } from "./AccountState";

const accountReducer = (
  state: AccountState,
  action: AccountActions,
): AccountState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default accountReducer;
