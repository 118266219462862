import { Container, Group, Paper } from "@mantine/core";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
interface HomePageProps {
  children: ReactNode;
}

function HomePage({ children }: HomePageProps) {
  return (
    <>
      <Helmet>
        <title>Eventsys - Home</title>
        <meta name="description" content="Eventsys Story"></meta>
      </Helmet>

      <Container size="xl">
        <Group position="apart" align="start">
          <Paper shadow="md" p="md" w={420}>
            <h2 className="text-3xl font-bold mb-4">
              <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                Welcome to Eventsys
              </span>
            </h2>
            <p className="text-gray-600 mb-8">
              Eventsys is the ultimate hub for creativity, expression, and
              community-building. Whether you're a seasoned writer, a
              photography enthusiast, a social butterfly, or simply seeking new
              connections, signing up with Eventsys opens the door to an
              enriching experience. Write captivating stories, create stunning
              albums, exchange heartfelt messages, and explore a world of
              endless possibilities. Join Eventsys today and embark on a journey
              of self-expression, creativity, and meaningful connections like
              never before.
            </p>
          </Paper>
          <Paper shadow="md" p="lg" w={520}>
            {children}
          </Paper>
        </Group>
      </Container>
    </>
  );
}
export default HomePage;
