import { createContext, useContext, useEffect, useState } from "react";
import { AccountService } from "../services/api";
import useAuthApi from "../services/useAuthApi";

import { GlobalActionTypes } from "./State/Global/GlobalActions";
import { StoreContext } from "./State/StoreContext";

interface AuthorizationContextData {
  isAuthenticated: boolean;
  authenticated: () => boolean;

  logout: () => void;
}

const AuthorizationContext = createContext<AuthorizationContextData>({
  isAuthenticated: false,
  authenticated: () => false,
  logout: () => {},
});

export const useAuthorization = () => useContext(AuthorizationContext);

export function AuthorizationProvider({ children }) {
  const { state, dispatch } = useContext(StoreContext);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const { handleAuthRequest } = useAuthApi();

  useEffect(() => {
    const jwtToken = localStorage.getItem("JWTToken");
    const authorised = localStorage.getItem("Authorized") ?? (false as boolean);
    if (authorised && jwtToken) {
      handleAuthRequest(
        AccountService.accountCurrentMe().then((r) => {
          if (r.isSucces) {
            dispatch({ type: GlobalActionTypes.CurrentMe, payload: r });
            dispatch({ type: GlobalActionTypes.Authorise, payload: jwtToken });

            setIsAuthenticated(true);
          } else {
            dispatch({ type: GlobalActionTypes.UnAuthorise });
          }
        }),
      );
    }
  }, [dispatch, handleAuthRequest, state.global.authenticated]);

  const logout = () => {
    dispatch({ type: GlobalActionTypes.UnAuthorise });
  };

  const authenticated = (): boolean => {
    return state.global.authenticated;
  };

  const contextValue: AuthorizationContextData = {
    isAuthenticated,
    authenticated,
    logout,
  };

  return (
    <AuthorizationContext.Provider value={contextValue}>
      {children}
    </AuthorizationContext.Provider>
  );
}
