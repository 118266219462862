import { ProfileAction } from "./ProfileActions";
import { ProfileState } from "./ProfileState";

const profileReducer = (
  state: ProfileState,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default profileReducer;
