/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountCreateModel } from "../models/AccountCreateModel";
import type { AccountForgotPasswordChangeModel } from "../models/AccountForgotPasswordChangeModel";
import type { AccountForgotPasswordChangeResult } from "../models/AccountForgotPasswordChangeResult";
import type { AccountForgotPasswordModel } from "../models/AccountForgotPasswordModel";
import type { AccountLoginModel } from "../models/AccountLoginModel";
import type { AccountLoginResult } from "../models/AccountLoginResult";
import type { BaseResponse } from "../models/BaseResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class PublicAccountService {
  /**
   * @param requestBody
   * @returns AccountLoginResult
   * @throws ApiError
   */
  public static publicAccountLogin(
    requestBody: AccountLoginModel,
  ): CancelablePromise<AccountLoginResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Account/Login",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param requestBody
   * @returns BaseResponse
   * @throws ApiError
   */
  public static publicAccountResetPasswordCode(
    requestBody: AccountForgotPasswordModel,
  ): CancelablePromise<BaseResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Account/Password/Reset",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param externalId
   * @param requestBody
   * @returns AccountForgotPasswordChangeResult
   * @throws ApiError
   */
  public static publicAccountResetPasswordRequest(
    externalId: string,
    requestBody: AccountForgotPasswordChangeModel,
  ): CancelablePromise<AccountForgotPasswordChangeResult> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Account/Password/Reset/{externalId}",
      path: {
        externalId: externalId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * @param externalId
   * @param verificationCode
   * @returns BaseResponse
   * @throws ApiError
   */
  public static publicAccountVerifyEmail(
    externalId?: string,
    verificationCode?: string,
  ): CancelablePromise<BaseResponse> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Account/VerifyEmail",
      query: {
        externalId: externalId,
        verificationCode: verificationCode,
      },
    });
  }

  /**
   * @param requestBody
   * @returns BaseResponse
   * @throws ApiError
   */
  public static publicAccountRegister(
    requestBody: AccountCreateModel,
  ): CancelablePromise<BaseResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Account/Register",
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
