// Register.tsx
import {
  Anchor,
  Badge,
  Box,
  Button,
  Center,
  Container,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IconArrowLeft, IconAt } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { FaFemale, FaGenderless, FaMale } from "react-icons/fa";
import { AccountCreateModel } from "../../services/api/models/AccountCreateModel";
import { PublicAccountService } from "../../services/api/services/PublicAccountService";
import useApi from "../../services/useApi";

const genderOptions = [
  { value: "male", icon: <FaMale />, label: "Male", color: "blue" },
  { value: "female", icon: <FaFemale />, label: "Female", color: "pink" },
  { value: "other", icon: <FaGenderless />, label: "Other", color: "gray" },
];

const Register: React.FC = () => {
  const { dismissError, error, isLoading, handleRequest } = useApi();

  const [formData, setFormData] = useState<{
    username: string;
    email: string;
    password: string;
    dateOfBirth: Date | null;
    gender: string;
  }>({
    username: "",
    email: "",
    password: "",
    dateOfBirth: null,
    gender: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleDateChange = (dob: Date) => {
    setFormData((prevFormData) => ({ ...prevFormData, dateOfBirth: dob }));
  };
  const handleGenderChange = (selectedGender: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      gender: selectedGender,
    }));
  };

  const [isFormFilled, setIsFormFilled] = useState(false);
  useEffect(() => {
    const isFormFilled =
      1 == 1 &&
      validateEmail(formData.email) &&
      formData.email != "" &&
      validateUsername(formData.username) &&
      formData.username != "" &&
      formData.password != "" &&
      formData.dateOfBirth != null &&
      formData.gender != "";
    setIsFormFilled(isFormFilled);
  }, [formData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const AccountCreateModel = {
      userName: formData.username,
      email: formData.email,
      password: formData.password,
      dateOfBirth: formData.dateOfBirth.toISOString(),
    } as AccountCreateModel;
    console.log(AccountCreateModel);
    handleRequest(
      PublicAccountService.publicAccountRegister(AccountCreateModel),
    ).then((response) => {
      if (response) {
        console.log("ok");
      }
    });
  };

  const handleValidateEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setIsEmailValid(validateEmail(value));
  };

  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const validateEmail = (email: string) => {
    if (email === "") return false;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleValidateUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setIsUsernameValid(validateUsername(value));
  };
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true);
  const validateUsername = (username: string) => {
    if (username === "") return false;
    const usernameRegex = /^[a-zA-Z0-9_]{6,32}$/;
    return usernameRegex.test(username);
  };

  return (
    <>
      <Container>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Sign up!
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Welcome! sign up and start the fun
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form onSubmit={handleSubmit}>
            <TextInput
              icon={<IconAt size="0.8rem" />}
              label="Email"
              name="email"
              placeholder="you@mail.com"
              value={formData.email}
              onChange={handleInputChange}
              onBlur={handleValidateEmail}
              error={!isEmailValid}
              required
            />

            <TextInput
              label="Username"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleInputChange}
              onBlur={handleValidateUsername}
              error={!isUsernameValid}
              required
            />

            <PasswordInput
              label="Password"
              required
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
            />

            <DateInput
              required
              name="dob"
              label="Date of Birth"
              value={formData.dateOfBirth}
              onChange={handleDateChange}
              placeholder="Select date"
            />

            <label>Gender *</label>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 8 }}
            >
              {genderOptions.map((option) => (
                <Badge
                  size={formData.gender === option.value ? "xl" : "md"}
                  variant={
                    formData.gender === option.value ? "filled" : "outline"
                  }
                  key={option.value}
                  style={{ cursor: "pointer", margin: "0 5px" }}
                  onClick={() => handleGenderChange(option.value)}
                  color={
                    formData.gender === option.value ? option.color : "gray"
                  }
                  title={option.label}
                >
                  {option.icon}
                </Badge>
              ))}
            </div>

            <Group position="apart" mt="lg" align="start">
              <Anchor color="dimmed" size="sm" href="/login">
                <Center inline>
                  <IconArrowLeft size={rem(12)} stroke={1.5} />
                  <Box ml={5}>Back to the login page</Box>
                </Center>
              </Anchor>

              <Button
                type="submit"
                variant="gradient"
                w={200}
                disabled={!isFormFilled}
              >
                Register
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default Register;
