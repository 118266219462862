import React, { createContext, useReducer } from "react";

import accountReducer from "./Account/AccountReducer";
import { initialAccountState } from "./Account/AccountState";
import profileReducer from "./Profile/ProfileReducer";
import { initialProfileState } from "./Profile/ProfileState";
import { initialGlobalState } from "./Global/GlobalState";
import globalReducer from "./Global/GlobalReducer";

// Combine initial states
const initialState = {
  global: initialGlobalState,
  account: initialAccountState,
  profile: initialProfileState,
  // Add other initial states here
};

// Combine reducers
const rootReducer = (state, action) => ({
  global: globalReducer(state.account, action),
  account: accountReducer(state.account, action),
  profile: profileReducer(state.profile, action),
  // Add other reducers here
});

const StoreContext = createContext<{
  state: typeof initialState;
  dispatch: React.Dispatch<any>; // Change this to the correct action type if available
}>({
  state: initialState,
  dispatch: () => {}, // Provide a dummy function
});

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
}

export { StoreContext, StoreProvider };
