import { AspectRatio, Card, Container } from "@mantine/core";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

interface WebcamComponentProps {
  autoCapture: boolean;
  handleCapture: (file: File | null) => void;
}

const WebcamComponent: FunctionComponent<WebcamComponentProps> = ({
  handleCapture,
  autoCapture,
}) => {
  const webcamRef = useRef(null);
  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState([]);

  const [hasCamera, setHasCamera] = useState(false);

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  useEffect(() => {
    if (autoCapture) {
      const intervalId = setInterval(capturePhoto, 1000);
      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    const checkCameraAvailability = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        console.log(devices);
        setDevices(devices);
        const hasVideoInput = devices.some(
          (device) => device.kind === "videoinput",
        );
        setHasCamera(hasVideoInput);
      } catch (error) {
        console.error("Error checking camera availability:", error);
      }
    };

    checkCameraAvailability();
  }, []);

  const capturePhoto = () => {
    if (webcamRef) {
      const capturedPhoto = webcamRef.current.getScreenshot();
      if (capturedPhoto) {
        const blob = dataURLtoBlob(capturedPhoto);
        const file = new File([blob], "captured-photo.jpg");
        handleCapture(file);
      }
    }
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL: string) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <>
      {hasCamera && (
        <>
          <Card>
            <Container size="30rem" px={0}>
              <AspectRatio ratio={16 / 9}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  videoConstraints={videoConstraints}
                  screenshotFormat="image/jpeg"
                />
              </AspectRatio>
            </Container>
            <button type="button" onClick={capturePhoto}>
              Capture Photo
            </button>
          </Card>
        </>
      )}
    </>
  );
};

export default WebcamComponent;
