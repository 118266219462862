import { useCallback, useState } from "react";
import { ApiError } from "./api/core/ApiError";
import { OpenAPI } from "./api/core/OpenAPI";

export function useApi() {
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(true);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  OpenAPI.BASE = apiBaseUrl;

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsloading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (error) {
      setError(error as ApiError);
      throw error;
    } finally {
      setIsloading(true);
    }
  }, []);

  function dismissError() {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
}

export default useApi;
