// ForgotPassword.tsx
import {
  Button,
  Container,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PublicAccountService } from "../../services/api/services/PublicAccountService";
import useApi from "../../services/useApi";

const VerifyEmail: React.FC = () => {
  const { dismissError, error, isLoading, handleRequest } = useApi();
  const [formData, setFormData] = useState<{
    externalId: string;
    code: string;
  }>({
    externalId: "",
    code: "",
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const externalId = searchParams.get("externalId") ?? "";
  const code = searchParams.get("code") ?? "";

  useEffect(() => {
    setFormData({ externalId, code });
  }, [externalId, code]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleRequest(
      PublicAccountService.publicAccountVerifyEmail(
        formData.externalId,
        formData.code,
      ),
    ).then((response) => {
      if (response) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Container>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Verify your account
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your verification code
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form onSubmit={handleSubmit}>
            <TextInput
              name="id"
              disabled
              placeholder="Id"
              value={formData.externalId}
              required
            />
            <PasswordInput
              label="Password"
              required
              name="code"
              placeholder="Code"
              value={formData.code}
              onChange={handleInputChange}
            />

            <Button type="submit" variant="gradient" fullWidth mt="xl">
              Verify your acount
            </Button>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default VerifyEmail;
