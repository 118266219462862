import {
  Avatar,
  Button,
  FileInput,
  Group,
  Paper,
  Text,
  rem,
} from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import WebcamComponent from "../../Components/Elements/WebcamComponent";

import { AccountService } from "../../services/api";
import useAuthApi from "../../services/useAuthApi";
import { StoreContext } from "../../Contexts/State/StoreContext";
import { GlobalActionTypes } from "../../Contexts/State/Global/GlobalActions";

function MyHome() {
  const { state, dispatch } = useContext(StoreContext);
  const { handleAuthRequest } = useAuthApi();

  const handleInputChange = (file: File) => {
    const formData = { file };
    console.log("submit?");

    handleAuthRequest(
      AccountService.accountUpdateAvator(formData).then((r) => {
        console.log("submit?", r);
        if (r.isSucces) {
        }
      }),
    );
  };

  useEffect(() => {
    dispatch({ type: GlobalActionTypes.INCREMENT });
  }, []);

  return (
    <>
      <Helmet>
        <title>Eventsys - My Home</title>
        <meta name="description" content="Eventsys Story"></meta>
      </Helmet>

      <Paper shadow="sm" radius="md" p="sm" withBorder>
        <Group position="apart" align="start">
          <Paper radius="md" withBorder p="lg">
            <Avatar src="" size={120} radius={120} mx="auto" />
            <Text ta="center" fz="lg" weight={500} mt="md">
              {state.global.currentMe?.username}
            </Text>
            <Text ta="center" c="dimmed" fz="sm"></Text>

            <Button variant="default" fullWidth mt="md">
              Change display picture
            </Button>
            <Button variant="default" fullWidth mt="md">
              Change profile
            </Button>
          </Paper>
          <Paper radius="md" withBorder p="lg" w={690}>
            <form>
              <FileInput
                accept="image/png,image/jpeg"
                onChange={handleInputChange}
                label="Your display picture"
                placeholder="Your photo"
                radius="xl"
                icon={<IconUpload size={rem(14)} />}
              />
              <WebcamComponent
                autoCapture={true}
                handleCapture={handleInputChange}
              ></WebcamComponent>
            </form>
          </Paper>
        </Group>
      </Paper>
    </>
  );
}
export default MyHome;
