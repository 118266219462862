import { Avatar, Group, Menu, Text, UnstyledButton } from "@mantine/core";
import {
  IconLogout,
  IconMessageCircle,
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react";
import { useContext } from "react";
import { HiBookOpen } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "../../../Contexts/AuthorisationContext";
import { StoreContext } from "../../../Contexts/State/StoreContext";

function AuthorisedHeaderMenu() {
  const { logout } = useAuthorization();
  const { state } = useContext(StoreContext);
  const navigate = useNavigate();

  const handleNavigate = (to: string) => () => {
    navigate(to);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <UnstyledButton>
          <Group>
            <Avatar size={40} color="blue">
              Me
            </Avatar>
            <div>
              <Text>{state.global.currentMe?.username}</Text>
              <Text size="xs" color="dimmed">
                {state.global.currentMe?.username}
              </Text>
            </div>
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>My account</Menu.Label>
        <Menu.Item
          onClick={handleNavigate("/my-home")}
          icon={<IconSettings size={14} />}
        >
          Settings
        </Menu.Item>
        <Menu.Item
          onClick={handleNavigate("/stories")}
          icon={<HiBookOpen size={14} />}
        >
          Stories
        </Menu.Item>
        <Menu.Item
          onClick={handleNavigate("/messages")}
          icon={<IconMessageCircle size={14} />}
        >
          Messages
        </Menu.Item>
        <Menu.Item icon={<IconPhoto size={14} />}>Gallery</Menu.Item>
        <Menu.Divider />
        <Menu.Item
          onClick={handleLogout}
          color="red"
          icon={<IconLogout size={14} />}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
export default AuthorisedHeaderMenu;
