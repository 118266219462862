import { FiUsers } from "react-icons/fi";
import {
  HiBookOpen,
  HiOutlineChatAlt,
  HiOutlinePhotograph,
  HiOutlineThumbUp,
} from "react-icons/hi";
import HeaderNavItem from "../Components/HeaderNavItem";

function AuthorisedMenu() {
  return (
    <>
      <HeaderNavItem
        link={"/stories"}
        text={"Stories"}
        icon={HiBookOpen}
        iconSize={25}
      />
      <HeaderNavItem
        link={"/messages"}
        text={"Messages"}
        icon={HiOutlineChatAlt}
        iconSize={25}
      />
      <HeaderNavItem link={"#"} text={"Friends"} icon={FiUsers} iconSize={25} />
      <HeaderNavItem
        link={"#"}
        text={"Likes"}
        icon={HiOutlineThumbUp}
        iconSize={25}
      />
      <HeaderNavItem
        link={"#"}
        text={"Albums"}
        icon={HiOutlinePhotograph}
        iconSize={25}
      />
    </>
  );
}
export default AuthorisedMenu;
