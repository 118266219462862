/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthorisationResult } from "../models/AuthorisationResult";
import type { BaseResponse } from "../models/BaseResponse";
import type { CurrentMeResult } from "../models/CurrentMeResult";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AccountService {
  /**
   * @returns CurrentMeResult
   * @throws ApiError
   */
  public static accountCurrentMe(): CancelablePromise<CurrentMeResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Account/Current/Me",
    });
  }

  /**
   * @returns AuthorisationResult
   * @throws ApiError
   */
  public static accountLogout(): CancelablePromise<AuthorisationResult> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/api/Account/Logout",
    });
  }

  /**
   * @param formData
   * @returns BaseResponse
   * @throws ApiError
   */
  public static accountUpdateAvator(formData?: {
    file?: Blob | null;
  }): CancelablePromise<BaseResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/api/Account/Avatar",
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }
}
