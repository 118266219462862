import { ColorScheme, Group, Header } from "@mantine/core";

import { useContext } from "react";
import { StoreContext } from "../../Contexts/State/StoreContext";
import AuthorisedHeaderMenu from "./Fragments/AuthorisedHeaderMenu";
import AuthorisedMenu from "./Fragments/AuthorisedMenu";
import Logo from "./Fragments/Logo";
import ThemeSwitch from "./Fragments/ThemeSwitch";
import UnAuthorisedMenu from "./Fragments/UnAuthorisedMenu";
import HeaderProps from "./Models/HeaderProps";

function HeaderBar({ toggleDarkMode }: HeaderProps) {
  const { state } = useContext(StoreContext);

  return (
    <>
      {state.global.authenticated}
      <Header height={70} mb={120} className="bg-orange-300 dark:bg-slate-700">
        <Group position="apart">
          <Logo />
          <Group position="center">
            {state.global.authenticated ? (
              <AuthorisedMenu />
            ) : (
              <UnAuthorisedMenu />
            )}
          </Group>
          <Group>
            {state.global.authenticated ? <AuthorisedHeaderMenu /> : <></>}
            <ThemeSwitch
              isDarkMode={state.global.darkMode}
              colorScheme={
                !state.global.darkMode
                  ? ("dark" as ColorScheme)
                  : ("light" as ColorScheme)
              }
              toggleDarkMode={toggleDarkMode}
            ></ThemeSwitch>
          </Group>
        </Group>
      </Header>
    </>
  );
}

export default HeaderBar;
