import { HiCake, HiLogin } from "react-icons/hi";
import HeaderNavItem from "../Components/HeaderNavItem";

function UnAuthorisedMenu() {
  return (
    <>
      <HeaderNavItem
        link={"/login"}
        text={"Login"}
        icon={HiLogin}
        iconSize={25}
      />
      <HeaderNavItem
        link={"/register"}
        text={"Sign Up"}
        icon={HiCake}
        iconSize={25}
      />
    </>
  );
}
export default UnAuthorisedMenu;
