import { Group, Text, UnstyledButton } from "@mantine/core";
import React from "react";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";

interface HeaderNavItemProps {
  link: string;
  icon: IconType;
  iconSize: number;
  text: string;
}

//function HeaderNavItem({}) {
const HeaderNavItem: React.FC<HeaderNavItemProps> = ({
  link,
  icon,
  iconSize,
  text,
}) => {
  return (
    <>
      <Link to={link}>
        <UnstyledButton>
          <Group position="apart" align="start">
            {React.createElement(icon, { size: iconSize })}
            <Text>{text}</Text>
          </Group>
        </UnstyledButton>
      </Link>
    </>
  );
};
export default HeaderNavItem;
