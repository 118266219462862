import { CurrentMeResult } from "../../../services/api";

export interface GlobalState {
  currentMe: CurrentMeResult | null;
  authenticated: boolean | false;
  jwtToken: string | null;
  darkMode: boolean | null;

  counter: number;
  // Add other state properties here if needed
}

// Add other action types here if needed

// Initial state for your global store
export const initialGlobalState: GlobalState = {
  currentMe: null,
  authenticated: false,
  jwtToken: null,
  darkMode: null,

  counter: 0,
  // Initialize other state properties if needed
};
