import { useCallback, useState } from "react";
import { ApiError } from "./api/core/ApiError";
import { OpenAPI } from "./api/core/OpenAPI";

export function useAuthApi() {
  const [authError, setAuthError] = useState<ApiError | undefined>(undefined);
  const [authIsLoading, setAuthIsloading] = useState<boolean>(true);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  OpenAPI.BASE = apiBaseUrl;
  OpenAPI.TOKEN = localStorage.getItem("JWTToken");

  const handleAuthRequest = useCallback(async function <T>(
    request: Promise<T>,
  ) {
    setAuthIsloading(true);
    try {
      const response = await request;
      setAuthError(undefined);
      return response;
    } catch (error) {
      setAuthError(error as ApiError);
      throw error;
    } finally {
      setAuthIsloading(true);
    }
  }, []);

  function dismissAuthError() {
    setAuthError(undefined);
  }

  return { dismissAuthError, authError, authIsLoading, handleAuthRequest };
}

export default useAuthApi;
