import {
  Anchor,
  Button,
  Container,
  Group,
  Paper,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalActionTypes } from "../../Contexts/State/Global/GlobalActions";
import { StoreContext } from "../../Contexts/State/StoreContext";
import { PublicAccountService } from "../../services/api";
import useApi from "../../services/useApi";

function Login() {
  const navigate = useNavigate();

  const { dispatch } = useContext(StoreContext);

  const [formData, setFormData] = useState<{ email: string; password: string }>(
    {
      email: "",
      password: "",
    },
  );
  const { handleRequest } = useApi();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    handleRequest(
      PublicAccountService.publicAccountLogin({
        usernameOrEmail: formData.email,
        password: formData.password,
      }),
    ).then((response) => {
      if (response.isSucces) {
        dispatch({
          type: GlobalActionTypes.Authorise,
          payload: response.token,
        });
        navigate("/my-home");
      }
    });
  };

  return (
    <Container>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Welcome back!
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{" "}
        <Anchor size="sm" href="/register">
          Create account
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <form onSubmit={handleSubmit}>
          <TextInput
            label="Email"
            name="email"
            placeholder="your@mail.com"
            value={formData.email}
            onChange={handleInputChange}
            withAsterisk
            required
          />

          <PasswordInput
            label="Password"
            withAsterisk
            mt="md"
            required
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
          />

          <Group position="apart" mt="lg">
            <Anchor href="/forgotten-password" size="sm">
              Forgot password?
            </Anchor>
          </Group>

          <Button type="submit" variant="gradient" fullWidth mt="xl">
            Sign in
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default Login;
function useAuthorisedApi(): {
  dismissError: any;
  error: any;
  isLoading: any;
  handleRequest: any;
} {
  throw new Error("Function not implemented.");
}
