import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Group,
  Image,
  Paper,
  Text,
} from "@mantine/core";
import { Helmet } from "react-helmet-async";
import { HiClock, HiPencil, HiThumbDown, HiThumbUp } from "react-icons/hi";

function StoryPage() {
  return (
    <>
      <Helmet>
        <title>Eventsys - Stories</title>
        <meta name="description" content="Eventsys Story"></meta>
      </Helmet>
      <div className="w-auto">
        <Paper shadow="xs" p="md" w="lg" className="w-auto">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Card.Section>
              <Image
                src="https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"
                height={160}
                alt="Norway"
              />
            </Card.Section>

            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>My First Post</Text>
              <Badge color="pink" variant="light">
                <HiClock title="Posted" />
                2023-07-13 @ 09:44<span className="px-3"></span>
                <HiPencil title="Edited" />
                2023-07-13 @ 16:44
              </Badge>
            </Group>

            <Group position="right" mt="md" mb="xs">
              <Badge color="green" variant="light" className="h-8">
                <div className="flex">
                  <ActionIcon title="Toggle theme" size="md">
                    <HiThumbUp title="Yeah" className="w-5 h-7 mr-1" />
                  </ActionIcon>

                  <ActionIcon title="Toggle theme" size="md">
                    <HiThumbDown title="Nah" className="w-5 h-7 mr-1" />
                  </ActionIcon>
                </div>
              </Badge>
            </Group>

            <Text size="sm" color="dimmed">
              <span className="break-all">
                Hello world
                sdssdfdaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaas
              </span>
            </Text>
            <Group grow={true}>
              <Button variant="light" color="blue" mt="md" radius="md">
                (0) Comment(s)
              </Button>
            </Group>
          </Card>
          <div className="h-4"></div>
        </Paper>
      </div>
    </>
  );
}
export default StoryPage;
