import { ActionIcon } from "@mantine/core";
import { FiMoon, FiSun } from "react-icons/fi";
import HeaderProps from "../Models/HeaderProps";

function ThemeSwitch({ colorScheme, isDarkMode, toggleDarkMode }: HeaderProps) {
  return (
    <>
      <ActionIcon
        mr={20}
        onClick={() => toggleDarkMode()}
        size="lg"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          color:
            theme.colorScheme === "dark"
              ? theme.colors.yellow[4]
              : theme.colors.blue[6],
        })}
      >
        {colorScheme === "dark" ? (
          <FiSun size="1.2rem" />
        ) : (
          <FiMoon size="1.2rem" />
        )}
      </ActionIcon>
    </>
  );
}
export default ThemeSwitch;
