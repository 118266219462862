import { Anchor, UnstyledButton } from "@mantine/core";

function Logo() {
  return (
    <>
      <Anchor href="/login" ml={20}>
        <UnstyledButton>
          <h1 className="text-lg font-bold ">
            <span className="font-extrabold text-transparent text-4xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              EventSys
            </span>
          </h1>
        </UnstyledButton>
      </Anchor>
    </>
  );
}
export default Logo;
