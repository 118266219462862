// ForgotPassword.tsx
import {
  Anchor,
  Box,
  Button,
  Center,
  Container,
  Group,
  Paper,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import React, { useState } from "react";
import { PublicAccountService } from "../../services/api/services/PublicAccountService";
import useApi from "../../services/useApi";

const ForgotPassword: React.FC = () => {
  const [formData, setFormData] = useState<{ email: string }>({
    email: "",
  });
  const { dismissError, error, isLoading, handleRequest } = useApi();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleRequest(
      PublicAccountService.publicAccountResetPasswordCode({
        emailOrUsername: formData.email,
      }),
    ).then((response) => {
      if (response.isSucces) {
        console.log("ok");
      }
    });
  };

  return (
    <>
      <Container>
        <Title
          align="center"
          sx={(theme) => ({
            fontFamily: `Greycliff CF, ${theme.fontFamily}`,
            fontWeight: 900,
          })}
        >
          Forgot your password?
        </Title>
        <Text c="dimmed" fz="sm" ta="center">
          Enter your email to get a reset link
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <form onSubmit={handleSubmit}>
            <TextInput
              label="Email"
              name="email"
              placeholder="you@mail.com"
              value={formData.email}
              onChange={handleInputChange}
              required
            />

            <Group position="apart" mt="lg" align="start">
              <Anchor color="dimmed" size="sm" href="/login">
                <Center inline>
                  <IconArrowLeft size={rem(12)} stroke={1.5} />
                  <Box ml={5}>Back to the login page</Box>
                </Center>
              </Anchor>
              <Button type="submit" variant="gradient" w={200}>
                Reset password
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPassword;
