import { Helmet } from "react-helmet-async";

function MessagesPage() {
  return (
    <>
      <Helmet>
        <title>Eventsys - Messages</title>
        <meta name="description" content="Eventsys Story"></meta>
      </Helmet>
    </>
  );
}
export default MessagesPage;
