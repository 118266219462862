import { useContext, useEffect } from "react";

import {
  AppShell,
  ColorScheme,
  ColorSchemeProvider,
  Container,
  MantineProvider,
} from "@mantine/core";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import RouterOutlet from "./Components/Routes/RouterOutlet";
import MyRoutes from "./Components/Routes/Routes";

import { GlobalActionTypes } from "./Contexts/State/Global/GlobalActions";
import { StoreContext } from "./Contexts/State/StoreContext";
import { FooterNav } from "./Layout/FooterNav";
import HeaderBar from "./Layout/Header/Header";

function App() {
  const { state, dispatch } = useContext(StoreContext);

  const toggleDarkMode = () => {
    dispatch({
      type: GlobalActionTypes.Theme,
      payload: !state.global.darkMode,
    });
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("JWTToken");
    if (jwtToken !== null && jwtToken !== undefined && jwtToken.length > 0) {
      dispatch({ type: GlobalActionTypes.Authorise, payload: jwtToken });
    }

    const theme = localStorage.getItem("Theme");
    dispatch({ type: GlobalActionTypes.Theme, payload: theme === "true" });
  }, []);

  return (
    <RouterOutlet>
      <div
        className={`app ${
          !state.global.darkMode
            ? ("dark" as ColorScheme)
            : ("light" as ColorScheme)
        }`}
      >
        <HelmetProvider>
          <MantineProvider
            theme={{
              colorScheme: !state.global.darkMode
                ? ("dark" as ColorScheme)
                : ("light" as ColorScheme),
            }}
            withGlobalStyles
            withNormalizeCSS
          >
            <ColorSchemeProvider
              toggleColorScheme={toggleDarkMode}
              colorScheme={
                !state.global.darkMode
                  ? ("dark" as ColorScheme)
                  : ("light" as ColorScheme)
              }
            >
              <AppShell
                padding="md"
                footer={<FooterNav></FooterNav>}
                //navbar={<Navbar width={{ base: 300 }} height={500} p="xs">{/* Navbar content */}</Navbar>}
                header={
                  <HeaderBar
                    colorScheme={
                      !state.global.darkMode
                        ? ("dark" as ColorScheme)
                        : ("light" as ColorScheme)
                    }
                    isDarkMode={state.global.darkMode}
                    toggleDarkMode={toggleDarkMode}
                  />
                }
                styles={(theme) => ({
                  main: {
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.colors.gray[0],
                  },
                })}
              >
                {
                  <Container maw={1024} mx="auto">
                    <MyRoutes />
                  </Container>
                }
              </AppShell>
            </ColorSchemeProvider>
          </MantineProvider>
        </HelmetProvider>
      </div>
    </RouterOutlet>
  );
}

export default App;
