import { GlobalActionTypes, GlobalActions } from "./GlobalActions";
import { GlobalState } from "./GlobalState";

const globalReducer = (
  state: GlobalState,
  action: GlobalActions,
): GlobalState => {
  switch (action.type) {
    case GlobalActionTypes.Authorise:
      localStorage.setItem("JWTToken", action.payload);
      localStorage.setItem("Authorized", true.toString());
      return { ...state, authenticated: true, jwtToken: action.payload };

    case GlobalActionTypes.UnAuthorise:
      localStorage.setItem("JWTToken", "");
      localStorage.setItem("Authorized", false.toString());
      return { ...state, authenticated: false, jwtToken: "" };

    case GlobalActionTypes.CurrentMe:
      console.log(action);

      return { ...state, currentMe: action.payload };

    case GlobalActionTypes.Theme:
      console.log("Theme", action.payload.toString());
      localStorage.setItem("Theme", action.payload.toString());
      return { ...state, darkMode: action.payload };

    case GlobalActionTypes.INCREMENT:
      return { ...state, counter: state.counter + 1 };
    case GlobalActionTypes.DECREMENT:
      return { ...state, counter: state.counter - 1 };
    // Handle other action types and state updates here
    default:
      return state;
  }
};

export default globalReducer;
