import { Route, Routes } from "react-router-dom";
import MyHome from "../../Pages/Authorised/MyHome";
import HomePage from "../../Pages/Home";
import MessagesPage from "../../Pages/MessagesPage";
import StoryPage from "../../Pages/StoryPage";
import ForgotPassword from "../Authorisation/ForgotPassword";
import Login from "../Authorisation/Login";
import Register from "../Authorisation/Register";
import VerifyEmail from "../Authorisation/VerifyEmail";
import ProtectedRoutes from "./ProtectedRoutes";

function MyRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomePage>
            <Login />
          </HomePage>
        }
      />
      <Route
        path="/login"
        element={
          <HomePage>
            <Login />
          </HomePage>
        }
      />
      <Route
        path="/register"
        element={
          <HomePage>
            <Register />
          </HomePage>
        }
      />
      <Route
        path="/forgotten-password"
        element={
          <HomePage>
            <ForgotPassword />
          </HomePage>
        }
      />
      <Route
        path="/verify-email"
        element={
          <HomePage>
            <VerifyEmail />
          </HomePage>
        }
      />
      <Route element={<ProtectedRoutes />}>
        <Route path="/my-home" element={<MyHome></MyHome>} />

        <Route path="/stories" element={<StoryPage />} />
        <Route path="/messages" element={<MessagesPage />} />
      </Route>
    </Routes>
  );
}
export default MyRoutes;
