import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthorizationProvider } from "./Contexts/AuthorisationContext";
import { StoreProvider } from "./Contexts/State/StoreContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <StoreProvider>
    <AuthorizationProvider>
      <App />
    </AuthorizationProvider>
  </StoreProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
